import { DAY_MONTH, MONTH_DAY } from '../constants';
import formattedTime from './formattedTime';

export default function getFormattedDay(day, locale, dateFormat = DAY_MONTH) {
  return day?.date
    ? formattedTime(
        day.date,
        dateFormat === MONTH_DAY ? 'dddd, MMMM D, YYYY' : 'dddd, D MMMM YYYY',
        locale,
      )
    : '';
}
