import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getImgixUrl } from '../../../../../helpers';
import Link from '../../../../shared/Link/Link';
import './AgendaSponsors.scss';

export function AgendaSponsors(props) {
  const {
    sponsors,
    pageConfig: { tenantId },
  } = props;

  return (
    <div className="agenda-sponsor-wrapper" data-testid="agenda-sponsors">
      {sponsors.map((item, index) => {
        const backgroundImage = getImgixUrl(
          tenantId,
          item.fullLogoPath,
          'w=400&h=210',
          '/images/default-silhouette.png',
        );

        return (
          <div key={index} className="agenda-item-sponsor">
            <Link
              to={`/sponsors/${item.path}`}
              event
              className="agenda-item-sponsor__link"
            >
              <div
                className="agenda-item-sponsor__logo lazy"
                style={{
                  backgroundImage: backgroundImage
                    ? `url(${backgroundImage})`
                    : null,
                }}
              />
            </Link>
          </div>
        );
      })}
    </div>
  );
}

AgendaSponsors.propTypes = {
  sponsors: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(AgendaSponsors);
