import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withSessionsIndexMap } from '../../context';

import { renderHTMLString } from '../../../../../helpers';
import AgendaSpeakers from '../../AgendaCommonSections/AgendaSpeakers/AgendaSpeakers';
import AgendaSponsors from '../../AgendaCommonSections/AgendaSponsors/AgendaSponsors';
import AgendaTimeStatus from '../../../../shared/AgendaTimeStatus/AgendaTimeStatus';

import './AgendaSession.scss';
import { getSessionIndexId } from '../../../../../utils/getSessions';
import AgendaCustomLabel from '../../components/AgendaCustomLabel';

export class AgendaSession extends Component {
  static propTypes = {
    streamId: PropTypes.string,
    session: PropTypes.object,
    sessionsIndexMap: PropTypes.object,
    currentView: PropTypes.string,
    isDateAndTimeDisplayed: PropTypes.bool,
    isLearning: PropTypes.bool,
    secondaryColor: PropTypes.string,
    clickSessionHandler: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.handleSessionClick = this.handleSessionClick.bind(this);
  }

  handleSessionClick() {
    const {
      session: { type, enabled = true },
      session,
      clickSessionHandler,
      sessionsIndexMap,
      streamId,
    } = this.props;
    const sessionIndex = sessionsIndexMap[getSessionIndexId(session, streamId)];

    if (
      type !== 'space' &&
      enabled &&
      clickSessionHandler &&
      typeof clickSessionHandler === 'function' &&
      sessionIndex !== undefined
    ) {
      clickSessionHandler(sessionIndex);
    }
  }

  render() {
    const {
      session,
      currentView,
      isDateAndTimeDisplayed,
      isLearning,
      secondaryColor,
      t,
    } = this.props;
    const colorIndex = session.stream && session.stream.color;
    const sessionFormat = session.format?.toUpperCase();

    return (
      <div
        className={`agenda-sessions ${session.type} ${currentView} ${
          colorIndex || session.group ? 'stream-border-color' : ''
        }`}
        data-stream-color-index={colorIndex}
        onClick={this.handleSessionClick}
      >
        {isLearning && sessionFormat && sessionFormat !== 'NONE' && (
          <span
            className="learning-agenda-item-format-label"
            style={{
              backgroundColor: secondaryColor,
            }}
          >
            {t(`AgendaSessionFormat.${sessionFormat}`)}
          </span>
        )}
        {isDateAndTimeDisplayed && (
          <AgendaTimeStatus session={session} isDuration />
        )}
        {session.labels && session.labels.includes('Free') && (
          <span className="agenda-item-free-label">
            {t('event.agenda.filter.label.free')}
          </span>
        )}

        {session.customLabel?.checked && (
          <AgendaCustomLabel labelConfig={session.customLabel} />
        )}

        {session.stream &&
          session.group &&
          !session.group.suppressStreamTitles && (
            <div className="stream">{session.stream.name}</div>
          )}
        <div className="title">{renderHTMLString(session.title)}</div>
        {currentView === 'expanded_list' &&
          session.description &&
          !(isLearning && !session.showInInfo) && (
            <div className="description">
              {renderHTMLString(session.description)}
            </div>
          )}
        {session.speakers && <AgendaSpeakers speakers={session.speakers} />}
        {(currentView === 'expanded_list' || session.showSponsors) &&
          session.sponsors && <AgendaSponsors sponsors={session.sponsors} />}
      </div>
    );
  }
}

export default withTranslation()(withSessionsIndexMap(AgendaSession));
