import React from 'react';
import PropTypes from 'prop-types';

import AgendaGroup from './AgendaGroup/AgendaGroup';
import AgendaSession from './AgendaSession/AgendaSession';

import './AgendaGrid.scss';

export function AgendaGrid(props) {
  const { items = [], streams, sidebarIsHidden, clickSessionHandler } = props;
  return (
    items.length > 0 && (
      <div className="c-agenda-grid-view">
        {items.map((item, index) => {
          let AgendaItemTemplate;

          switch (item.type) {
            case 'stream_group':
              AgendaItemTemplate = AgendaGroup;
              break;

            case 'session':
            case 'session_break':
              AgendaItemTemplate = AgendaSession;
              break;

            default:
              break;
          }

          return AgendaItemTemplate ? (
            <AgendaItemTemplate
              key={index}
              content={item}
              streams={streams}
              sidebarIsHidden={sidebarIsHidden}
              clickSessionHandler={clickSessionHandler}
            />
          ) : null;
        })}
      </div>
    )
  );
}

AgendaGrid.propTypes = {
  items: PropTypes.array,
  streams: PropTypes.array,
  sidebarIsHidden: PropTypes.bool,
  clickSessionHandler: PropTypes.func,
};

export default AgendaGrid;
